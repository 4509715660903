<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                拥抱自然，聚力前行！迎接变化，持续迭代！
              </p>
              <div class="fst-italic mb-2">2022年10月9日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >团建活动</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/15/01-东方绿舟.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    融于集体，才能发挥最大能量。两天一晚的“东方绿舟”团建活动，为非凸人再次启航蓄满能量。这次，我们团队又壮大了，技术实力不容小觑。非凸科技推行工程师文化，有着共同的技术信仰，以Rust作为底层架构，保障交易系统的安全与稳定。
                  </p>
                   <p>
                    做热爱且擅长的事，持续精进。非凸科技一直专注投入一件事，那就是算法策略的研发和迭代，帮助机构客户降低成本，增厚收益。在看似重复的迭代中，不断修整方向，探索新的路径和方法。
                  </p>
                  <h4 class="my-4">东方绿舟自由行</h4>
                  <p>
                    清新的空气，辽阔的天际，葱郁的树木，深邃的湖泊，壮观的航母，让我们陶醉其中。不仅零距离拥抱自然，还能收获国防知识，树立正确价值观。
                  </p>
                   <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/15/02-合影.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                 <h4 class="my-4">户外烧烤</h4>
                  <p>
                    人间烟火气，最抚凡人心。远离城市的喧嚣，围在烤炉旁，体验着“自己动手，丰衣足食”的乐趣。烧烤过程中，大家还展示了自家的“独门绝技”。欢声笑语，其乐融融。
                  </p>
                   <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/15/03-烧烤.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <h4 class="my-4">卡丁车友谊赛</h4>
                  <p>
                    超速飞驰、极速漂移、高难度拐弯......争分夺秒，你追我赶，冲向终点。赛场上，每一次都是速度与激情的交锋，每一次都是自信与勇气的较量。激发潜能，超越自我，赛出最好的成绩。
                  </p>
                   <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/15/04-卡丁车.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <h4 class="my-4">非凸首届德州扑克大赛</h4>
                  <p>
                    这是一场智慧的博弈，精彩的对决。在合作与竞争中，相互了解，促进情感，彼此成就。
                  </p>
                  <p>
                    参赛选手，有久经沙场的老将，也有初生牛犊不怕虎的新星。选手们在打法上各有特色，紧凶、紧弱、松凶、松弱，轮番上阵。经过激烈角逐，9人进入总决赛，最终冠军花落谁家？
                  </p>
                   <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/15/05-德扑.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    短暂的放松休整，可以振奋精神，扬起斗志。未来，我们将勇于涉险滩，敢于闯难关，迎接每一次的变化和挑战。
                  </p>
                  <p>
                    期待，下一次的出发！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News12",
};
</script>

<style></style>
